<template>
  <div>
    <Tag/>
  </div>
</template>

<script>
import Tag from '@/components/Tag.vue'

export default {
  name: "NewTag",
  components: {
    Tag
  }
}
</script>

